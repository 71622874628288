<template>
  <ib-expand-panel
    :value="visible"
    :expandable="false"
    color="violet"
  >
    <!-- Title slot -->
    <template #title>
      <div class="title-wrapper my-3 my-md-0">
        <div class="title" :class="{'animation': !visible}">
          <i class="icon ib-icon ib-icon-magic-2" />
          <div class="d-flex font-outfit-regular ai-buddy-title">
            AI Buddy
          </div>
        </div>
        <div class="tabs">
          <ib-button-tab
            color="violet1"
            :is-active="activeComponent === 'AiGetSuggestions'"
            class="my-3 my-md-0 ml-md-2"
            @click="onClick('AiGetSuggestions')"
          >
            <span class="font-outfit-medium">{{ $t('aiBuddy.getSuggestions.title') }}</span>
          </ib-button-tab>

          <!--          <ib-button-tab-->
          <!--            :is-active="activeComponent === 'AiHelpMeWrite'"-->
          <!--            class="ml-md-2"-->
          <!--            @click="onClick('AiHelpMeWrite')"-->
          <!--          >-->
          <!--            <span class="font-outfit-medium">{{ $t('aiBuddy.helpMeWrite.title') }}</span>-->
          <!--          </ib-button-tab>-->
        </div>
      </div>
    </template>

    <!-- Get Suggestion -->
    <ai-get-suggestions
      v-show="activeComponent === 'AiGetSuggestions'"
      :type="type"
      @click="onSuggestionClick"
      @close="onClose"
    />
    <!-- Get Suggestion -->

    <!-- Help me write -->
    <!--    <ai-help-me-write-->
    <!--      v-show="activeComponent === 'AiHelpMeWrite'"-->
    <!--      :type="type"-->
    <!--      @close="onClose"-->
    <!--    />-->
    <!-- Help me write -->

  </ib-expand-panel>
</template>

<script>

import AiGetSuggestions from '@/views/Home/StoryMode/Components/AiBuddy/AiGetSuggestions.vue'
// import AiHelpMeWrite from '@/views/Home/StoryMode/Components/AiBuddy/AiHelpMeWrite.vue'

export default {
  name: 'AiBuddy',

  components: {
    // AiHelpMeWrite,
    AiGetSuggestions
  },

  provide () {
    return {
      visible: () => this.visible
    }
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      visible: false,
      activeComponent: null
    }
  },

  methods: {
    onClick (component) {
      this.visible = true
      this.activeComponent = component
    },

    onSuggestionClick (suggestion) {
      this.$emit('suggestion', suggestion)
    },

    onClose () {
      this.visible = false
      setTimeout(() => {
        this.activeComponent = null
      }, 250)
    }
  }
}
</script>

<style scoped lang="scss">

.title-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media-breakpoint-up($md) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
    }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: linear-gradient(to right, #8509F6 30%, #ED6F7C 50%, #0060ee 70%, #8509F6 90%);
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.animation {
      animation: shine 3s linear infinite;
    }

    .ai-buddy-title {
      font-size: 17px;
    }

    .icon {
      font-size: 22px !important;
      //margin-bottom: 5px;
    }
  }

  @keyframes shine {
    to {
      background-position: -200% center;
    }
  }

  .tabs {
    display: flex;
    //justify-content: center;
    align-items: center;
    flex-direction: column;
    //width: 100%;
    padding: 0 20px 0 35px;

    @include media-breakpoint-up($md) {
      flex-direction: row;
      padding: 0;
    }
  }
}

</style>
