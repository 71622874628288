<template>
  <!--  <ib-tooltip-->
  <!--    popper-class="small center"-->
  <!--    :content="$t('getAiAssistanceForThisSection')"-->
  <!--  >-->
  <button
    class="ai-buddy-button"
    :class="{'disabled': disabled}"
    :style="{'height': height}"
    @click="onClick"
  >
    <div class="ai-buddy-button-title animation d-flex font-outfit-regular">
      <div class="d-flex align-items-baseline">
        <i class="ib-icon ib-icon-magic-2" />
        <span :class="{'d-none d-md-flex': responsive}">AI Buddy</span>
      </div>
      <!--      <div-->
      <!--        :class="{'d-none d-md-flex': responsive}"-->
      <!--        class="beta-text"-->
      <!--      >-->
      <!--        BETA-->
      <!--      </div>-->
    </div>
  </button>
<!--  </ib-tooltip>-->
</template>

<script>
export default {
  name: 'AiBuddyButton',

  props: {
    height: {
      type: String,
      default: '38px'
    },
    responsive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return

      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.ai-buddy-button {
  background: $color-ai-light;
  border: 2px solid $color-ai-light;
  border-radius: 8px;
  transition: all 0.25s;

  &.disabled {
    opacity: .5;
    cursor: not-allowed;

    &:hover {
      border: 2px solid $color-ai-light;
    }
  }

  &:hover {
    border: 2px solid $color-ai;
  }

  ::v-deep {
    padding: 0 10px;
  }

  .ib-icon {
    font-size: 22px;
    align-self: center;

    @include media-breakpoint-up($md) {
      border: 2px solid $color-ai-light;
    }
  }

  .ai-buddy-button-title {
    color: $color-ai;
    font-size: 17px;
    //background: linear-gradient(to right, #8509F6 30%, #ED6F7C 50%, #0060ee 70%, #8509F6 90%);
    //background-size: 200% auto;
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;

    //&.animation {
    //  animation: shine 3s linear infinite;
    //}

    .beta-text {
      font-size: 10px;
      font-weight: 900 !important;
      margin-left: 5px;
    }
  }

  //@keyframes shine {
  //  to {
  //    background-position: -200% center;
  //  }
  //}
}

</style>
