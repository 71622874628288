import { render, staticRenderFns } from "./AiBuddy.vue?vue&type=template&id=1195781d&scoped=true"
import script from "./AiBuddy.vue?vue&type=script&lang=js"
export * from "./AiBuddy.vue?vue&type=script&lang=js"
import style0 from "./AiBuddy.vue?vue&type=style&index=0&id=1195781d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1195781d",
  null
  
)

export default component.exports